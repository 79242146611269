<template>
    <div>
        <p class="font-semibold">List Notifikas</p>
        <br>
        <div class="row justify-content-center">
          <div class="col-md-6 scrollbarnya" style="height:100vh;overflow:scroll;">
            <div class="bg-white rounded-lg shadow p-3 mt-2 mb-2" v-for="(item, index) in notifikasis" :key="index+'notifkaiss'">
                <p class="font-semibold">{{item.title}}</p>
                <hr class="mt-1 mb-1">
                <div v-html="item.body" class="text-justify">

                </div>
            </div>
          </div>
        </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>
    </div>
</template>
<script>
import firebase from 'firebase'
let rdb = firebase.database();
import uniqid from 'uniqid'
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
export default{
    layout:'app_shop_admin',
    data(){
        return{
            notifikasis:[]
        }
    },
    mounted() {
        rdb.ref('/notifikasi/admin').on('value',(e)=>{ //read
            console.log(e.val())
            sdb.collection("app_kasir_notifikasi",false).doc().select(`select * from app_kasir_notifikasi where tujuan='admin' order by id desc`).then(res => {
                console.log(res);
                this.notifikasis=res;
            });
        }) 
    },
}
</script>
<style scoped>
.scrollbarnya::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
.scrollbarnya::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 5px;
}
 
/* Handle */
.scrollbarnya::-webkit-scrollbar-thumb {
  cursor: grab;
  background: black; 
  border-radius: 5px;
}

/* Handle on hover */
.scrollbarnya::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
</style>